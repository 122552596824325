.exploreContainer {
    margin: 10vh 20px 0px 20px;
    animation: fadeInTitle ease 0.5s;
}

.exploreButton {
    color: white;
    background-color: rgba(255, 255, 255, .3);
    border: none;
    margin: 10px 10px 40px 10px;
    width: 80px;
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.exploreText {
    min-height: 10vh;
    width: 80vw;
    border-radius: 20px;
    margin: 0px auto 20px auto;
    padding: 10px 10px 10px 10px;
}

.exploreExplainTitle {
    text-align: left;
    margin: 0px 0px 0px 20px;
}

.pronounce {
    margin: 0px 0px 40px 0px;
}

.exploreTitle {
    margin: 10px 0px 10px 0px;
}

.exploreLogoIcon {
    font-size: 50px;
    margin: 0px 0px 60px 0px;
    border: 1px white solid;
    background-color: rgba(255, 255, 255, .3);
    border-radius: 10px;
}

.nounText {
    text-align: left;
    margin: 30px 0px 30px 60px;
    font-size: 15px;
}

.exploreLearnText {
    text-align: left;
}

@media only screen and (min-width: 992px) {
    .nounText {
        text-align: left;
        margin: 30px 0px 30px 80px;
        font-size: 25px;
    }
}