.appContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.linkButton {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  height: 50px;
  width: 100px;
  border: none;
  border-radius: 10px;
  margin: 20vh 2vw 1vh 2vw;
  animation: fadeInButton ease 9s;
  font-family: 'Source Code Pro', monospace;
  font-size: 20px;
}

@keyframes fadeInButton{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.title {
  font-size: 60px;
  margin-top: 20vh;
  animation: fadeInTitle ease 3s;
}

@keyframes fadeInTitle{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.tagLine {
  font-size: 20px;
  animation: fadeInTag ease 7s;
}

@keyframes fadeInTag{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.registerLink {
  color: white;
  opacity: 0.7;
  margin-bottom: 10vh;
}

@media only screen and (min-width: 992px) {
  .linkButton {
    background-color: rgba(255, 255, 255, 0);
    height: 50px;
    width: 100px;
    border: 1px solid white;
    border-radius: 10px;
    margin: 6vh 2vw 1vh 2vw;
    font-size: 20px;
  }

  .title {
    margin-top: 30vh;
  }
}