.registerContainer {
    position: relative;
    border: 1px solid white;
    border-radius: 2vh;
    animation: fadeInTitle ease .5s;
    margin: 7vh 4vh 100px 4vh;
}

@keyframes fadeInTitle{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;

    }
}

.registerForm {
    display: flex;
    flex-direction: column;
    margin: 0vh 4vh 0vh 4vh;
}

.demoButton {
    background-color: white;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
}

.registerInput {
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
    
}

.registerButton {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    height: 50px;
    width: 100px;
    border: none;
    border-radius: 10px;
    margin: 4vh auto 5vh auto;
    font-family: 'Source Code Pro', monospace;
}

@media only screen and (min-width: 992px) {
    .registerContainer {
        border: none;
        margin: 7vh auto 0vh auto;
        width: 30vw;
    }
}