@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Source+Code+Pro:300&display=swap);
/* font-family: 'Open Sans Condensed', sans-serif;
font-family: 'Source Code Pro', monospace; */

body {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-width: 100vw;
  min-height: 100vh;
  font-family: "Exo", sans-serif;
  color: #fff;
  background: linear-gradient(-45deg, #16003B, #41003B, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: gradientBG 15s ease infinite;
          animation: gradientBG 15s ease infinite;
  font-family: 'Source Code Pro', monospace;
}

@-webkit-keyframes gradientBG {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@keyframes gradientBG {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.appContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.linkButton {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  height: 50px;
  width: 100px;
  border: none;
  border-radius: 10px;
  margin: 20vh 2vw 1vh 2vw;
  -webkit-animation: fadeInButton ease 9s;
          animation: fadeInButton ease 9s;
  font-family: 'Source Code Pro', monospace;
  font-size: 20px;
}

@-webkit-keyframes fadeInButton{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes fadeInButton{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.title {
  font-size: 60px;
  margin-top: 20vh;
  -webkit-animation: fadeInTitle ease 3s;
          animation: fadeInTitle ease 3s;
}

@-webkit-keyframes fadeInTitle{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes fadeInTitle{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.tagLine {
  font-size: 20px;
  -webkit-animation: fadeInTag ease 7s;
          animation: fadeInTag ease 7s;
}

@-webkit-keyframes fadeInTag{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes fadeInTag{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.registerLink {
  color: white;
  opacity: 0.7;
  margin-bottom: 10vh;
}

@media only screen and (min-width: 992px) {
  .linkButton {
    background-color: rgba(255, 255, 255, 0);
    height: 50px;
    width: 100px;
    border: 1px solid white;
    border-radius: 10px;
    margin: 6vh 2vw 1vh 2vw;
    font-size: 20px;
  }

  .title {
    margin-top: 30vh;
  }
}
.loginContainer {
    position: relative;
    border: 1px solid white;
    border-radius: 2vh;
    -webkit-animation: fadeInTitle ease 0.5s;
            animation: fadeInTitle ease 0.5s;
    margin: 7vh 4vh 0vh 4vh;
}

@-webkit-keyframes fadeInTitle{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

@keyframes fadeInTitle{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

form { 
    display: flex;
    flex-direction: column;
    margin: 0vh 4vh 0vh 4vh;    
}

.loginInput {
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.loginButton {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    height: 50px;
    width: 100px;
    border: none;
    border-radius: 10px;
    margin: 3vh auto 5vh auto;
    font-family: 'Source Code Pro', monospace;
}

.error {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: rgb(255, 182, 47);
    font-size: 20px;
}

.registerLoginLink {
    color: white;
    height: 23px;
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.7;
    margin-bottom: 2vh;
    margin-top: 0px;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
}

@media only screen and (min-width: 992px) {
    .loginContainer {
        border: none;
        margin: 7vh auto 0vh auto;
        width: 30vw;
    }
}
.navContainerMain {
    margin-top: 10px;
    min-height: 30px;
    width: 100vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.navContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-right:10px
}

.menuOption {
    margin: 12px 10px 10px 1px;
    border-radius: 100%;
    height: 30px;
    width: 30px;    
    border: 1px solid white;
    
}

.popOut {
    -webkit-animation: fadeIn ease .5s;
            animation: fadeIn ease .5s;
}

@-webkit-keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

.reframeLogo {
    margin-left: 20px;
    color:white;
}

.Icon {
    margin: 16px 0px 0px 0px;
    margin-left: 20px;
    color:white;
    font-size: 25px;
}

.logoNav {
    height: 8px;
    width: 8px;
    display: inline-block;
    border: 1px solid white;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.3);
    margin-right: 8px;
  }

.menuLogo {
    color: white;
    margin-top: 2px;
    font-size: 20px;
}

@media only screen and (min-width: 992px) {
  .menuOption {
    margin: 20px 30px 10px 1px;
    border-radius: 100%;
    height: 40px;
    width: 40px;    
    border: 2px solid white;
  }

  .reframeLogo {
    margin-left: 35px;
    color:white;
}

  .menuLogo {
    font-size: 29px;
}
}


.registerContainer {
    position: relative;
    border: 1px solid white;
    border-radius: 2vh;
    -webkit-animation: fadeInTitle ease .5s;
            animation: fadeInTitle ease .5s;
    margin: 7vh 4vh 100px 4vh;
}

@-webkit-keyframes fadeInTitle{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;

    }
}

@keyframes fadeInTitle{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;

    }
}

.registerForm {
    display: flex;
    flex-direction: column;
    margin: 0vh 4vh 0vh 4vh;
}

.demoButton {
    background-color: white;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
}

.registerInput {
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
    
}

.registerButton {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    height: 50px;
    width: 100px;
    border: none;
    border-radius: 10px;
    margin: 4vh auto 5vh auto;
    font-family: 'Source Code Pro', monospace;
}

@media only screen and (min-width: 992px) {
    .registerContainer {
        border: none;
        margin: 7vh auto 0vh auto;
        width: 30vw;
    }
}
.exploreContainer {
    margin: 10vh 20px 0px 20px;
    -webkit-animation: fadeInTitle ease 0.5s;
            animation: fadeInTitle ease 0.5s;
}

.exploreButton {
    color: white;
    background-color: rgba(255, 255, 255, .3);
    border: none;
    margin: 10px 10px 40px 10px;
    width: 80px;
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.exploreText {
    min-height: 10vh;
    width: 80vw;
    border-radius: 20px;
    margin: 0px auto 20px auto;
    padding: 10px 10px 10px 10px;
}

.exploreExplainTitle {
    text-align: left;
    margin: 0px 0px 0px 20px;
}

.pronounce {
    margin: 0px 0px 40px 0px;
}

.exploreTitle {
    margin: 10px 0px 10px 0px;
}

.exploreLogoIcon {
    font-size: 50px;
    margin: 0px 0px 60px 0px;
    border: 1px white solid;
    background-color: rgba(255, 255, 255, .3);
    border-radius: 10px;
}

.nounText {
    text-align: left;
    margin: 30px 0px 30px 60px;
    font-size: 15px;
}

.exploreLearnText {
    text-align: left;
}

@media only screen and (min-width: 992px) {
    .nounText {
        text-align: left;
        margin: 30px 0px 30px 80px;
        font-size: 25px;
    }
}
.yourMistakeContainer {
    min-height: 10vh;
    width: 90vw;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
}

.boxText {
    padding: 30px 20px 20px 20px;
}

.boxTextTitle {
    margin: 0px auto 0px auto;
    font-size: 25px;
}

.box {
    border-radius: 7px;
    background-color: white;
    height: 20px;
    width: 20px;
    margin: 0px 20px 0px 10px;
}

.boxChecked {
    background-color: rgb(224, 221, 4);
    height: 20px;
    width: 20px;
    margin: 0px 20px 0px 10px;
    border-radius: 7px;
}

.plainText {
    text-align: left !important;
    margin: 0px;
    width: 200px;
}

.lineItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.commentText {
    text-align: left !important;
    padding: 0px 20px 20px 20px
    
}

.buttonContainerMistake {
    font-family: 'Source Code Pro', monospace;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 10px 0px 10px;
}

.openButton {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    border-radius: 5px;
}

.mainTitle {
    margin-bottom: 0px;
}

.mainCatch {
    margin-top: 0px;
    margin-bottom: 0px;
}

.reframeMistakes {
    margin: 40px 0px 40px 0px;
}

.detailContainer {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 50px 15px 30px 15px;
    border: 1px solid white;
    border-radius: 10px;
    padding:0px 10px 0px 10px;
    background-color: rgba(255, 255, 255, 0.2);
}

.shareIcon {
    font-size: 20px;
}

.shareIconMain {
    font-size: 20px;
    margin: 12px 0px 0px 0px;
}

ul {
    padding: 0px 0px 0px 0px;
    text-align: left !important;
}

.openIcon {
    height: 15px;
    font-size: 20px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}

.collapsedTitle {
    margin-bottom: 0px;
}

.dataContainerMistake {
    font-family: 'Source Code Pro', monospace;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 20px 0px 20px;
}

.mainIcon {
    font-size: 40px;
}
/* use icons instead of nicknames */
@media only screen and (min-width: 992px) {
    .yourMistakeContainer {
        min-height: 10vh;
        width: 30vw;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
    }
}

.mistakeNumber {
    display: inline-block;
    width: 25px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
}

.sortButton {
    width: 80px;
    color: white;
    font-family: 'Source Code Pro', monospace;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    border-radius: 10px;
    margin: 0px 5px 0px 5px;
}

.sortBy {
    display: inline-block;
}

.userHomeContainer {
    -webkit-animation: fadeInTitle ease 2s;
            animation: fadeInTitle ease 2s;
}

.averageContainer {
    margin: 0px 20px 0px 20px;
    padding: 0px 10px 0px 10px;
    font-size: 20px;
}

.averageBadIcon {
    font-size: 175px !important;
    margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 992px) {
    .userHomeContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px auto 0px auto;
        width: 85vw;
    }

    .headContainer {
        position: fixed;
    }

    .mistakeMap {
        margin: 80px 0px 0px 45vw
    }
}


.mistakeoneContainer {
    margin: 15vh 0vh 0vh 0vh;
    -webkit-animation: fadeInTitle ease 2s;
            animation: fadeInTitle ease 2s;
}

@-webkit-keyframes fadeInTitle{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

@keyframes fadeInTitle{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

.mistakeButton {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    height: 100px;
    border: none;
    width: 100px;
    border-radius: 100%;
    margin: 6vh 3vh 5vh 3vh;
    font-family: 'Source Code Pro', monospace;
}

.mistakeoneTitle {
    padding: 10px;
}
.mistakeTwoContainer {
    min-height: 60vh;
    min-width: 50vh;
    border-radius: 20px;
    margin: 10px 20px 10px 20px;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-animation: fadeInTitle ease 2s;
            animation: fadeInTitle ease 2s;
}

.mistaketwoForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.buttonMistakeTwoContainer {
    padding-bottom: 20px;
}

.imgButton {
    height: 30px;
    width: 100px;
    border: 1px solid white;
    font-size: 12px;
    margin: 5px 10px 5px 10px;
    border-radius: 8px;
    color: white;
    background-color: rgba(255, 255, 255, .3);
    border: none;
}

.mistakeTwoInput {
    padding: 12px 20px;
    margin: 10px 0px 10px 0px;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.mistakeTwoLabel {
    text-align: left;
    font-size: 15px;
}

.shareContainer {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.shareContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.shareCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: #eee;
}
  
.shareContainer:hover input ~ .shareCheckmark {
    background-color: #ccc;
}
  
.shareContainer input:checked ~ .shareCheckmark {
    background-color: red;
}
  
.shareCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.shareContainer input:checked ~ .shareCheckmark:after {
    display: block;
}
  
.shareContainer .shareCheckmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.mistakeTwoButton {
    color: white;
    background-color: rgba(255, 255, 255, .3);
    border: none;
    margin: 10px auto 10px auto;
    width: 50px;
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.howBadText {
    padding: 0px 20px 0px 20px;
}

@media only screen and (min-width: 992px) {
    .mistakeTwoContainer {
        min-height: 60vh;
        width: 30vw;
        margin: 10px auto 100px auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.mistakeThreeContainer {
    min-height: 60vh;
    min-width: 50vh;
    border-radius: 20px;
    margin: 10px 20px 40px 20px;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-animation: fadeInTitle ease 2s;
            animation: fadeInTitle ease 2s;
}

.mistakethreeForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.mistakeThreeInput {
    padding: 12px 20px;
    margin: 10px 0px 10px 0px;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.mistakeThreeLabel {
    font-size: 15px;
    text-align: left;
}

.mistakeThreeButton {
    color: white;
    background-color: rgba(255, 255, 255, .3);
    border: none;
    margin: 10px auto 10px auto;
    width: 50px;
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.mistakeThreeTag {
    font-size: 15px;
    padding: 0px 10px 30px 10px
}

@media only screen and (min-width: 992px) {
    .mistakeThreeContainer {
        min-height: 60vh;
        width: 30vw;
        margin: 10px auto 100px auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.planContainer {
    min-height: 60vh;
    min-width: 50vh;
    border-radius: 20px;
    margin: 10px 20px 40px 20px;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-animation: fadeInTitle ease 2s;
            animation: fadeInTitle ease 2s;
}

.planForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.planInput {
    padding: 12px 20px;
    margin: 10px 0px 10px 0px;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.planLabel {
    font-size: 15px;
    text-align: left;
}

.planButton {
    color: white;
    background-color: rgba(255, 255, 255, .3);
    border: none;
    margin: 10px auto 10px auto;
    width: 80px;
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

@media only screen and (min-width: 992px) {
    .planContainer {
        min-height: 60vh;
        width: 30vw;
        margin: 10px auto 100px auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.mistakeBox {
    min-height: 10vh;
    width: 90vw;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
}

.boxTextFeed {
    padding: 0px 20px 0px 20px;
}

.boxTitleFeed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 20px 0px 20px;
}

.boxComment {
    min-width: 60vw;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.commentLabel {
    text-align: left;
    font-size: 15px;
}

.boxCommentButton {
    color: white;
    height: 43px;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    border: none;
    margin-left: 5px;
}

@media only screen and (min-width: 992px) {
    .mistakeBox {
        width: 50vw;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .boxComment {
        min-width: 35vw;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        border: 2px solid white;
        border-radius: 10px;
        font-family: 'Source Code Pro', monospace;
    }
}
.feedContainer {
    -webkit-animation: fadeInTitle ease 2s;
            animation: fadeInTitle ease 2s;
}

@media only screen and (min-width: 992px) {
    .sortContainerFeed {
        font-size: 25px;
    }
}

