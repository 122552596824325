.mistakeNumber {
    display: inline-block;
    width: 25px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
}

.sortButton {
    width: 80px;
    color: white;
    font-family: 'Source Code Pro', monospace;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    border-radius: 10px;
    margin: 0px 5px 0px 5px;
}

.sortBy {
    display: inline-block;
}

.userHomeContainer {
    animation: fadeInTitle ease 2s;
}

.averageContainer {
    margin: 0px 20px 0px 20px;
    padding: 0px 10px 0px 10px;
    font-size: 20px;
}

.averageBadIcon {
    font-size: 175px !important;
    margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 992px) {
    .userHomeContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px auto 0px auto;
        width: 85vw;
    }

    .headContainer {
        position: fixed;
    }

    .mistakeMap {
        margin: 80px 0px 0px 45vw
    }
}

