.yourMistakeContainer {
    min-height: 10vh;
    width: 90vw;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
}

.boxText {
    padding: 30px 20px 20px 20px;
}

.boxTextTitle {
    margin: 0px auto 0px auto;
    font-size: 25px;
}

.box {
    border-radius: 7px;
    background-color: white;
    height: 20px;
    width: 20px;
    margin: 0px 20px 0px 10px;
}

.boxChecked {
    background-color: rgb(224, 221, 4);
    height: 20px;
    width: 20px;
    margin: 0px 20px 0px 10px;
    border-radius: 7px;
}

.plainText {
    text-align: left !important;
    margin: 0px;
    width: 200px;
}

.lineItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.commentText {
    text-align: left !important;
    padding: 0px 20px 20px 20px
    
}

.buttonContainerMistake {
    font-family: 'Source Code Pro', monospace;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 10px 0px 10px;
}

.openButton {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    border-radius: 5px;
}

.mainTitle {
    margin-bottom: 0px;
}

.mainCatch {
    margin-top: 0px;
    margin-bottom: 0px;
}

.reframeMistakes {
    margin: 40px 0px 40px 0px;
}

.detailContainer {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 50px 15px 30px 15px;
    border: 1px solid white;
    border-radius: 10px;
    padding:0px 10px 0px 10px;
    background-color: rgba(255, 255, 255, 0.2);
}

.shareIcon {
    font-size: 20px;
}

.shareIconMain {
    font-size: 20px;
    margin: 12px 0px 0px 0px;
}

ul {
    padding: 0px 0px 0px 0px;
    text-align: left !important;
}

.openIcon {
    height: 15px;
    font-size: 20px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}

.collapsedTitle {
    margin-bottom: 0px;
}

.dataContainerMistake {
    font-family: 'Source Code Pro', monospace;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 20px 0px 20px;
}

.mainIcon {
    font-size: 40px;
}
/* use icons instead of nicknames */
@media only screen and (min-width: 992px) {
    .yourMistakeContainer {
        min-height: 10vh;
        width: 30vw;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
    }
}
