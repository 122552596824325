.mistakeoneContainer {
    margin: 15vh 0vh 0vh 0vh;
    animation: fadeInTitle ease 2s;
}

@keyframes fadeInTitle{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

.mistakeButton {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    height: 100px;
    border: none;
    width: 100px;
    border-radius: 100%;
    margin: 6vh 3vh 5vh 3vh;
    font-family: 'Source Code Pro', monospace;
}

.mistakeoneTitle {
    padding: 10px;
}