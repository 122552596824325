.mistakeThreeContainer {
    min-height: 60vh;
    min-width: 50vh;
    border-radius: 20px;
    margin: 10px 20px 40px 20px;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    animation: fadeInTitle ease 2s;
}

.mistakethreeForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.mistakeThreeInput {
    padding: 12px 20px;
    margin: 10px 0px 10px 0px;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.mistakeThreeLabel {
    font-size: 15px;
    text-align: left;
}

.mistakeThreeButton {
    color: white;
    background-color: rgba(255, 255, 255, .3);
    border: none;
    margin: 10px auto 10px auto;
    width: 50px;
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    font-family: 'Source Code Pro', monospace;
}

.mistakeThreeTag {
    font-size: 15px;
    padding: 0px 10px 30px 10px
}

@media only screen and (min-width: 992px) {
    .mistakeThreeContainer {
        min-height: 60vh;
        width: 30vw;
        margin: 10px auto 100px auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}