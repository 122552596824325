.navContainerMain {
    margin-top: 10px;
    min-height: 30px;
    width: 100vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.navContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-right:10px
}

.menuOption {
    margin: 12px 10px 10px 1px;
    border-radius: 100%;
    height: 30px;
    width: 30px;    
    border: 1px solid white;
    
}

.popOut {
    animation: fadeIn ease .5s;
}

@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

.reframeLogo {
    margin-left: 20px;
    color:white;
}

.Icon {
    margin: 16px 0px 0px 0px;
    margin-left: 20px;
    color:white;
    font-size: 25px;
}

.logoNav {
    height: 8px;
    width: 8px;
    display: inline-block;
    border: 1px solid white;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.3);
    margin-right: 8px;
  }

.menuLogo {
    color: white;
    margin-top: 2px;
    font-size: 20px;
}

@media only screen and (min-width: 992px) {
  .menuOption {
    margin: 20px 30px 10px 1px;
    border-radius: 100%;
    height: 40px;
    width: 40px;    
    border: 2px solid white;
  }

  .reframeLogo {
    margin-left: 35px;
    color:white;
}

  .menuLogo {
    font-size: 29px;
}
}

